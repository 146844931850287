.LocalNav {
  align-items: center;
  background: var(--color-white);
  border-bottom: 1px solid #dedede;
  display: flex;
  height: var(--nav-height);
  justify-content: space-between;
}

.LocalNav__GlobalAlert {
  color: var(--color-black);
  margin: 0;
  padding: 0 1rem;
}

.LocalNav--center {
  justify-content: center;
}

.LocalNav--sticky {
  position: sticky;
  top: 0;
}

.LocalNav__container {
  background: var(--color-white);
}

.LocalNav__address {
  color: var(--color-gray-dark);
  margin-right: 0.5rem;
}

.LocalNav__BrandContainer {
  display: flex;
  height: 100%;
}

.LocalNav__Brand {
  display: flex;
  height: 100%;
}

.LocalNav__Brand img {
  max-width: 100%;
  object-fit: contain;
  object-position: 0 center;
}

.LocalNav__Inner {
  align-items: center;
  display: flex;
  height: 100%;
}

.LocalNav .Button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}

.LocalNav__FeatureButtons {
  align-items: center;
  border-right: 1px solid var(--color-gray-dark);
  display: none;
  /* Hide on smaller screens */
  height: 2.5rem;
  margin-right: 1.5rem;
  padding-right: 0.5rem;
}

.LocalNav__FeatureButtons:empty {
  display: none;
}

.LocalNav__FeatureButtons .Button {
  align-content: center;
  border-radius: 50%;
  display: flex;
  font-size: 1.25rem;
  height: 2.25rem;
  justify-content: center;
  margin-right: 1rem;
  padding: 0;
  width: 2.25rem;
  align-items: center;
}

.LocalNav__Back {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  left: 0;
  margin-left: 1rem;
  padding: 0;
  position: absolute;
}

.Button.LocalNav__BtnRegistration {
  font-size: 0.75rem;
  padding: 0.375rem 0.75rem;
}

/* Hide buttons on small screens */
.Button.LocalNav__BtnMaptv {
  display: none;
}

.LocalNav .Button--with-icon svg {
  margin: 0;
  vertical-align: -0.2em;
}

.LocalNav__ShareableBallotBanner {
  margin-bottom: 0;
}

.LocalNav__ShareableBallotBanner .Notification__body .Button {
  margin-top: 0.5rem;
}

@media (max-width: 576px) {
  .LocalNav {
    justify-content: left;
  }
}

@media (min-width: 768px) {
  .LocalNav--center .LocalNav__Inner {
    position: absolute;
    right: 0;
  }

  .LocalNav {
    height: var(--nav-height--lg);
  }

  .LocalNav__container {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
  }

  .LocalNav__container .Notification {
    height: var(--global-alert-height);
  }

  .LocalNav__container .Notification.LocalNav__ShareableBallotBanner {
    height: var(--share-banner-height);
  }

  .Button.LocalNav__BtnMaptv {
    display: flex;
  }

  .Button.LocalNav__BtnRegistration {
    font-size: 0.875rem;
  }

  .LocalNav__FeatureButtons {
    display: flex;
  }

  .LocalNav .Button {
    margin-right: 1rem;
    padding: 0.5rem 1.5rem;
  }

  .LocalNav__FeatureButtons .Button {
    padding: 0;
  }

  .LocalNav__ShareableBallotBanner {
    font-size: 1rem;
  }

  .LocalNav__ShareableBallotBanner .Notification__body .Button {
    margin-left: 1rem;
    margin-top: 0;
  }

  .LocalNav__ShareableBallotBanner .Notification__body {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@media print {
  .LocalNav {
    display: none;
  }

  .LocalNav__FeatureButtons {
    display: none;
  }
}
